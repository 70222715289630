export const flatNo = [
  "101",
  "102",
  "103",
  "104",
  "--------",
  "201",
  "202",
  "203",
  "204",
  "--------",
  "301",
  "302",
  "303",
  "304",
  "--------",
  "401",
  "402",
  "403",
  "404",
  "--------",
  "501",
  "502",
  "503",
  "504",
  "--------",
  "601",
  "602",
];
