export const NAMES = [
  "---1---",
  "Vacant",
  "Saik Abdul Zahoor",
  "Vacant",
  "Vacant",
  "---2---",
  "Vacant",
  "Mohammed Mustaq Ahmed",
  "Abdul Azhar",
  "Abdul Nafe",
  "---3---",
  "Vacant",
  "Mohammed Sajid Mohiuddin",
  "Rais Ahamad",
  "Mohd Ghousuddin",
  "---4---",
  "Mohd Arshad",
  "Mohammed Ishaq",
  "Abdul Rahman",
  "Mohammed Azharuddin",
  "---5---",
  "Vacant",
  "Abdul Hameed",
  "Zahir Abdul Rauf Dhada",
  "Mohammad Gulam Mustafa",
  "---6---",
  "Vacant",
  "Qazi Syed Shoeb Ikram",
];
