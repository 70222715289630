export const Events = {
  TOTAL_UPDATED: "TOTAL_UPDATED",
  INCOME_ID: "INCOME_ID",
  EXPENSE_ID: "EXPENSE_ID",
  EXPENSE_LIST: "EXPENSE_LIST",
  INCOME_LIST: "INCOME_LIST",
  API_RESPONSE: "API_RESPONSE",
  CARRY_FORWARD: "CARRY_FORWARD",
  NEXT_MONTH_CF: "  NEXT_MONTH_CF",
};
